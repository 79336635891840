:root {
    --dawn: to bottom, #56a5ff, #bd5427, #ff6700, #ff8100, #ffa700, #a7c492;
    --day: to bottom, #568CD8, #82B2E8, #FFF8EF, #F4E8D4, #E9D6CA, #a7c492;
    --evening: to bottom, #293462, #F24C4C, #EC9B3B, #F7D716;
    --night: to bottom, #040404, #27183F, #370648, #C4595F, #F89C69, #FFF9CB;
    --shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(187, 184, 184),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3)
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .project-card {
        @apply block min-w-0 p-6 m-4 bg-gray-200 border border-gray-200 rounded-lg shadow-md hover:bg-gray-200;
    }

    .icon {
        @apply mx-5;
    }

    .footer {
        @apply p-5 inset-x-1/2 items-center flex flex-row justify-center md:bottom-5;
    }
    
    .education-card {
        @apply block flex flex-row max-w-md p-6 m-4 bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-200;
    }

    .work-list {
        @apply items-center flex flex-col justify-between mx-4 my-10 lg:flex-row
    }
}

@responsive {

}

@media (min-width: 640px) {

}

@media (min-width: 768px) {

}

html {
    min-height: 100vh;
    min-width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    font-family: 'League Spartan', sans-serif;
}

body {
    background-color: transparent;
}

h1 {
    font-size: larger;
}

p {
    /* font-size: calc(6px+1vmin); */
    margin: 5px 0px;
}

li {
    font-size: small;
}

.about-me {
    border-radius: 5px;
    box-shadow: var(--shadow);
    padding: 5px;
    max-width: 50rem;
}

.activity-widget {
    border: 4px solid rgb(65, 64, 64);
    flex: flex;
    background-color: rgb(211, 208, 208);
    overflow-y: auto;
    max-width: 75rem;
    height: 200px;
    border-radius: 5px;
    align-items: center;
    box-shadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
    padding: 5px;
}

.activity {
    border: 2px solid rgb(65, 64, 64);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
}

.background-dawn {
    background-image: linear-gradient(var(--dawn));
}

.background-day {
    background-image: linear-gradient(var(--day));
}

.background-evening {
    background-image: linear-gradient(var(--evening));
}

.background-night {
    background-image: linear-gradient(var(--night));
}

/* Style The Dropdown Button */
.dropbtn {
    background-color: rgb(211, 208, 208);
    border-radius: 5px;
    border: 2px solid rgb(105, 105, 105);
    box-shadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
    color: white;
    padding: 10px;
    font-size: 10px;
    cursor: pointer;
}
  
  /* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}
  
  /* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
  
  /* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
  
  /* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #f1f1f1;
}
  
  /* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
    border-radius: 5px;
}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: rgb(105, 105, 105);
    border: 2px solid rgb(211, 208, 208);
}

.icon {
    max-width: 50px;
    max-height: 50px;
}

.logo-card {
    /* background-color: rgb(211, 208, 208); */
    width: fit-content;
    border-radius: 5px;
    align-items: center;
    box-shadow: var(--shadow)
}

.mobile-icon {
    max-width: 26vw;
    max-height: 26vh;
}

.menu-icon {
    max-width: 20px;
    max-height: 20px;
}

.school-icon {
    max-width: 7vh;
    max-height: 5vh;
}

.selfie {
    border: solid 1px gray;
    border-radius: 50px;
    max-width: 30vh;
    max-height: 30vh;
    box-shadow: var(--shadow);
}
